import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { DayType } from "models/general";
import moment from "moment";
import { AccessPointAction } from "./enum";
import {
    DailyAccessEntry,
    TimeManagementTotals,
    TimeTrackingEntry,
    TimeTrackingHeaderData,
    TimeTrackingRecord,
} from "./model";
import {
    DailyAccessEntryResponse,
    TimeTrackingRecordResponse,
    TimeTrackingTableData,
    TimeTrackingTableDataResponse,
} from "./response";

export const getTimeTrackingHeaderDataEmpty = (): TimeTrackingHeaderData => {
    return {
        Persons: [],
        Groups: [],
        ActiveEmployees: [],
        EmpKeys: null,
        DifferenceSummary: {
            TimeDifferenceForYear: 0,
            TimeDifferenceUptoDate: 0,
        },
        EmployeePreparationTime: 0,
        Totals: {
            AvailableStaff: 0,
            BreakMinutes: 0,
            CareHours: 0,
            IN: 0,
            MaxCareHours: 0,
            SumOverdraft: 0,
            NeededEmployees: 0,
            OUT: 0,
            SumDifference: 0,
            InfoTotal: 0,
            ManualEntryDuration: 0,
            TotalRecords: 0,
        },
    };
};
export const getAccessPointActionString = (action: AccessPointAction) => {
    return t(`timeManagement.actions.${action.toLocaleLowerCase()}`).toString();
};

export const defaultHeaderTotals = () =>
({
    IN: 0,
    OUT: 0,
    AvailableStaff: 0,
    BreakMinutes: 0,
    CareHours: 0,
    MaxCareHours: 0,
    SumDifference: 0,
    SumOverdraft: 0,
    NeededEmployees: 0,
    TotalRecords: 0,
    InfoTotal: 0,
    ManualEntryDuration: 0,
} as TimeManagementTotals);

export const calculateHeaderTotals = (
    records: TimeTrackingRecord[],
    totalAvailableStaff: number,
    forEmployee = false
): TimeManagementTotals => {
    let totalBreakMinutes = 0;
    let totalCareMinute = 0;
    let totalMaxCareMinute = 0;
    let totalSumDifference = 0;
    let totalOverdraft = 0;
    let totalNeededEmployees = 0;
    let manualEntryDuration = 0;
    totalAvailableStaff = defaultTo(totalAvailableStaff, 0);

    for (const record of records) {
        totalBreakMinutes += defaultTo(record.BreakMinutes, 0);
        totalCareMinute += defaultTo(record.CareMinutes, 0);
        totalMaxCareMinute += record.MaxContractMinutes;
        totalSumDifference +=
            forEmployee && record.IgnoreDifference
                ? 0 // show difference if date is less than configured timeTrackingStart=0
                : forEmployee ||
                    (!forEmployee &&
                        (record.LastExitTime || record.FirstEntranceTime))
                    ? record.MaxContractMinutes > 0 ||
                        record.DifferenceSumMinutes != 0
                        ? record.DifferenceSumMinutes
                        : -record.MaxContractMinutes
                    : 0;
        if (!forEmployee && record.OverdraftSumMinutes < 0) {
            // days where parent picked child early
            totalOverdraft += record.OverdraftSumMinutes;
        }

        totalNeededEmployees += record.NeededEmployees;
        manualEntryDuration += record.ManualEntryDuration;
    }

    const inCount = records.filter(
        (x) => x.FirstEntranceTime != null && x.LastExitTime == null
    ).length;

    return {
        IN: inCount,
        OUT: records.filter(
            (s) =>
                s.LastExitTime != null ||
                s.AccessId == null ||
                // for manual entries with no daily event
                (forEmployee &&
                    s.AccessId != null &&
                    s.FirstEntranceTime == null &&
                    s.LastExitTime == null)
        ).length,
        AvailableStaff: totalAvailableStaff,
        BreakMinutes: totalBreakMinutes,
        CareHours: totalCareMinute,
        MaxCareHours: totalMaxCareMinute,
        SumDifference: totalSumDifference,
        SumOverdraft: totalOverdraft,
        NeededEmployees: totalNeededEmployees,
        TotalRecords: records.length,
        InfoTotal: totalAvailableStaff - totalNeededEmployees,
        ManualEntryDuration: manualEntryDuration,
    };
};
export const parseTimeTrackingTableResponse = (
    val: TimeTrackingTableDataResponse
): TimeTrackingTableData => {
    const records = val.Records.map((x: TimeTrackingRecordResponse) => {
        return {
            ...(x as TimeTrackingEntry),
            FirstEntranceTime: x.FirstEntranceTime
                ? moment(x.FirstEntranceTime, ISO8601_TIME_FORMAT)
                : null,
            LastExitTime: x.LastExitTime
                ? moment(x.LastExitTime, ISO8601_TIME_FORMAT)
                : null,
            Date: x.Date ? moment(x.Date, ISO8601_DATE_FORMAT) : null,
        } as TimeTrackingRecord;
    });
    return {
        ...val,
        Records: records,
        HeaderData: val.HeaderData,
        Timings: val.Timings
            ? {
                ...val.Timings,
                From: moment(val.Timings.From, ISO8601_TIME_FORMAT),
                To: moment(val.Timings.To, ISO8601_TIME_FORMAT),
            }
            : undefined,
    };
};
export const parseDailyAccessControlEntry = (
    value: DailyAccessEntryResponse
): DailyAccessEntry => {
    return {
        ActionType: value.ActionType,
        Id: value.Id,
        Ignored: value.Ignored,
        Time: value.Time ? moment(value.Time, ISO8601_TIME_FORMAT) : null,
        IsAutoGenerated: value.IsAutoGenerated,
        IsActionUpdated: value.IsActionUpdated,
    };
};

export const getDayTypeTranslation = (
    type: DayType,
    isEmployee: boolean = true
): string => {
    switch (type) {
        case DayType.WORK_DAY:
            return t(
                `timeManagement.dayType.${isEmployee ? "workDay" : "careDay"}`
            );
        case DayType.PUBLIC_HOLIDAY:
            return t("timeManagement.dayType.publicHoliday");
        case DayType.WEEKEND:
            return t("timeManagement.dayType.weekend");
        case DayType.PERSON_VACATION:
            return t("timeManagement.dayType.personVacation");
        case DayType.PERSON_HALF_VACATION:
            return t("timeManagement.dayType.personHalfVacation");
        case DayType.UNPAID_VACATION:
            return t("timeManagement.dayType.unpaidVacation");
        case DayType.DISEASE_DAY:
            return t("timeManagement.dayType.diseaseDay");
        case DayType.DISMANTLE_OVERTIME:
            return t("timeManagement.dayType.dismantleOvertime");
        case DayType.SCHOOL_DAY:
            return t("timeManagement.dayType.schoolDay");
        case DayType.FREE_DAY:
            return t("timeManagement.dayType.freeDay");
        case DayType.FURTHER_TRAINING:
            return t("timeManagement.dayType.furtherTraining");
        case DayType.NO_REASON:
            return t("timeManagement.dayType.noReason");
    }
};

export function calculateTimerInterval(
    intervalDurationInMinutes: number,
    refreshAtMinute: number,
    minRefetchDelay?: number
): number {
    const seconds = moment
        .duration(moment().diff(moment().startOf("h")))
        .asSeconds();
    const minutes = seconds / 60;

    let minutesTillNextRefetch: number =
        refreshAtMinute - (minutes % intervalDurationInMinutes);

    if (minutesTillNextRefetch < 0) {
        minutesTillNextRefetch += intervalDurationInMinutes;
    }
    if (
        minRefetchDelay &&
        minRefetchDelay > 0 &&
        Math.abs(minutesTillNextRefetch) <= minRefetchDelay
    ) {
        // to avoid refresh immediately on opening page
        return minutesTillNextRefetch + intervalDurationInMinutes;
    }
    return minutesTillNextRefetch;
}
