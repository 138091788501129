import { AppContainer } from "components/Containers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    KindergartenClientContractsList,
    KindergartenClientContractsListResponse,
    Kindergarten_Client_Contracts,
    parseKindergartenClientContractListResponse,
} from "models";
import React, { useState, useMemo, useEffect } from "react";
import { AppLoader } from "components/Loaders";
import { useActiveKindergartenClient } from "screens/kindergarten/clients/tabs/KindergartenClientTabs";
import { defaultTo, isNil } from "lodash-es";
import moment from "moment";
import {
    getKindergartenClientContractServiceKey,
    KindergartenClientContractService,
} from "services/kindergarten";
import { useQuery } from "react-query";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { AppResponse, Optional } from "models/general";
import { AppYearNavigationComponent } from "components";
import { KindergartenClientMonthlyContractsTable } from "./partials";
import styles from "./KindergartenClientContractsListScreen.module.scss";
import commonStyles from "commonPartials/commonStyles.module.scss";

export const KindergartenClientContractsListScreen: React.FC = () => {
    const { linkProvider } = useRouting();
    const { encodedId } = useSessionBusiness();
    const { dataItem: client } = useActiveKindergartenClient();
    const apiBase = useMemo(() => {
        return linkProvider.kindergarten.clients().api.withId().clientContracts;
    }, [linkProvider]);

    const contractsService = new KindergartenClientContractService(apiBase);
    const { getPermissionMap } = useCheckPermission();
    const [activeYear, setActiveYear] = useState<number>(
        client?.StartOfCare ? client?.StartOfCare.year() : moment().year()
    );
    const [data, setData] =
        useState<Optional<KindergartenClientContractsList>>(undefined);

    const permMap = getPermissionMap(Kindergarten_Client_Contracts);

    const {
        isFetching,
        isLoading,
        data: response,
        error,
        refetch,
    } = useQuery(
        getKindergartenClientContractServiceKey("contractsList", {
            encodedBusinessId: encodedId,
            clientId: client?.Id,
            year: activeYear,
        }),
        async () => await contractsService.contractsList(activeYear), // automatically refetch based on key change
        {
            select: (
                resp: AppResponse<KindergartenClientContractsListResponse>
            ) => {
                if (resp && resp.Data) {
                    return parseKindergartenClientContractListResponse(
                        resp.Data
                    );
                }
                return null;
            },
        }
    );

    useEffect(() => {
        if (!isFetching && response) {
            setData(response);
        }
    }, [isFetching]);
    useEffect(() => {
        if (!isFetching) {
            refetch();
        }
    }, [activeYear, client?.Id]);

    return (
        <AppContainer
            heightToAdjust={215}
            classes={{ body: commonStyles.appContainerWithLessTopPadding }}
        >
            <div className={styles.root}>
                <AppYearNavigationComponent
                    years={defaultTo(data?.YearsRange, [activeYear])}
                    value={activeYear}
                    readonly={isFetching}
                    className={styles.yearSelector}
                    onChange={(y: number) => setActiveYear(y)}
                />

                {isNil(client) || isNil(data) || isLoading ? (
                    <AppLoader />
                ) : (
                    <KindergartenClientMonthlyContractsTable
                        refetchData={() => {
                            refetch();
                        }}
                        loading={isFetching}
                        permMap={permMap}
                        data={data}
                    />
                )}
            </div>
        </AppContainer>
    );
};

export default KindergartenClientContractsListScreen;
