import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import { SearchField } from "components/FormFields";
import { SEARCH_DELAY_TIME } from "globals/constants";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil, orderBy } from "lodash";
import { Business_Archives, PermissionAccessTypes } from "models";
import {
    DocumentArchive,
    DocumentArchiveFilter,
    DocumentArchiveRequest,
    DocumentArchiveSort,
    DocumentArchiveSortColumn,
    getDocumentArchive,
} from "models/archive";
import {
    ResponseMeta,
    SortOrder,
    UpdateDisplayIdResponseAndRequest,
} from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    DocumentArchiveService,
    getDocumentArchiveServiceKey,
} from "services/business";
import {
    DocumentArchiveCreateEditDialog,
    DocumentArchiveTable,
} from "./partials";

export interface DocumentArchiveManagementState {
    openDialog: boolean;
    filter: DocumentArchiveFilter;
    meta: ResponseMeta;
    sort: DocumentArchiveSort;
    list: DocumentArchive[];
    itemForUpdateId?: number;
}
const PAGE_SIZE = 25;
export const DocumentArchiveManagement: React.FC = () => {
    const { t } = useTranslation();
    const { id: sessionBusinessId } = useSessionBusiness();

    const [state, setState] = useState<DocumentArchiveManagementState>({
        openDialog: false,
        filter: { Name: undefined },
        meta: { PageNumber: 1, PageSize: PAGE_SIZE },
        sort: {
            sortColumn: DocumentArchiveSortColumn.DisplayId,
            sortOrder: SortOrder.ASC,
        },
        list: [],
        itemForUpdateId: undefined,
    });

    const [search, setSearch] = useState<string | undefined>();

    const { linkProvider } = useRouting();

    const documentArchiveService = new DocumentArchiveService(
        linkProvider.business.api.currentBusiness().documentArchive
    );

    const request = {
        Page: state.meta.PageNumber,
        PageSize: PAGE_SIZE,
        Name: state.filter.Name,
        SortColumn: state.sort.sortColumn,
        SortOrder: state.sort.sortOrder,
    } as DocumentArchiveRequest;

    const {
        isRefetching: getAllRefetching,
        isLoading: getAllLoading,
        data: documentArchiveListResponse,
        refetch,
    } = useQuery(
        getDocumentArchiveServiceKey("getDocumentArchiveList", {
            ...request,
            businessId: sessionBusinessId,
        }),
        async () => await documentArchiveService.getDocumentArchiveList(request)
    );

    const {
        isLoading: loading,
        data: response,
        mutate: createEditDocumentArchive,
    } = useMutation(
        async (request: DocumentArchive) =>
            await documentArchiveService.createUpdate(request)
    );

    useEffect(() => {
        if (!getAllLoading && documentArchiveListResponse?.Meta) {
            const convertedData = documentArchiveListResponse.Data.map((x) =>
                getDocumentArchive(x)
            );
            setState({
                ...state,
                list:
                    documentArchiveListResponse.Meta.PageNumber == 1
                        ? convertedData
                        : [...state.list, ...convertedData],
                meta: documentArchiveListResponse.Meta,
            });
        }
    }, [getAllLoading, documentArchiveListResponse]);

    useEffect(() => {
        if (!loading && response) {
            if (response.Data != null) {
                setState({
                    ...state,
                    openDialog: false,
                    itemForUpdateId: undefined,
                });
                if (response.Data.Id && response.Data.Id > 0) {
                    showSweetAlertToast(
                        t("common.success"),
                        `"${response.Data.Name}" ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );
                } else {
                    showSweetAlertToast(
                        t("common.success"),
                        `"${response.Data.Name}" ${t(
                            "common.actions.createdSuccessfully"
                        )}`,
                        "success"
                    );
                }
                if (state.meta.PageNumber != 1) {
                    setState({
                        ...state,
                        meta: {
                            ...state.meta,
                            PageNumber: 1,
                        },
                    });
                } else {
                    refetch();
                }
            } else if (response.Code && response.Errors) {
                let errorHtml = "";
                response.Errors.forEach((error) => {
                    errorHtml = errorHtml + error.Message + "<br/>";
                });
                showSweetAlertToast(
                    t("common.error.error"),
                    errorHtml,
                    "error"
                );
            }
        }
    }, [response, loading]);
    const updateDisplayId = (req: UpdateDisplayIdResponseAndRequest) => {
        setState({
            ...state,
            list: state.list.map((x) => {
                if (x.Id == req.Id) {
                    x.DisplayId = req.DisplayId;
                }
                return x;
            }),
        });
    };

    const filterList = () => {
        setState({
            ...state,
            list: orderBy(
                state.list,
                ["DisplayId"],
                state.sort.sortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
            ),
        });
    };
    const removeItem = (Id: number) => {
        setState({
            ...state,
            list: state.list.filter((x) => x.Id != Id),
        });
        refetch();
    };

    const onEditClick = (id: number) => {
        setState({
            ...state,
            itemForUpdateId: id,
            openDialog: true,
        });
    };

    useEffect(() => {
        if (!isNil(search)) {
            const delayDebounceFn = setTimeout(() => {
                setState({
                    ...state,
                    filter: {
                        ...state.filter,
                        Name: search,
                    },
                    meta: {
                        ...state.meta,
                        PageNumber: 1,
                    },
                });
            }, SEARCH_DELAY_TIME);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);

    const { checkPermission } = useCheckPermission();
    const hasCreatePermission = checkPermission(Business_Archives, [
        PermissionAccessTypes.CREATE,
    ]);
    return (
        <>
            {state.openDialog && (
                <DocumentArchiveCreateEditDialog
                    updateArchiveId={state.itemForUpdateId}
                    loading={loading}
                    modalOpen={state.openDialog}
                    onClose={() =>
                        setState({
                            ...state,
                            openDialog: false,
                            itemForUpdateId: undefined,
                        })
                    }
                    title={t(
                        `${
                            state.itemForUpdateId
                                ? "documentArchive.updateArchive"
                                : "documentArchive.createArchive"
                        }`
                    )}
                    onSubmit={createEditDocumentArchive}
                />
            )}

            <AppContentHeader
                title={t("documentArchive.archive")}
                icon={ImageAssets.common.fileBlue}
            >
                {hasCreatePermission && (
                    <AppRoundedTextIconButton
                        onClick={() => setState({ ...state, openDialog: true })}
                    />
                )}
            </AppContentHeader>
            <div className="d-flex justify-content-end mb-3">
                <div>
                    <SearchField
                        value={defaultTo(search, "")}
                        onValueChange={(value) => setSearch(value)}
                    />
                </div>
            </div>
            <DocumentArchiveTable
                documents={state.list}
                loading={getAllLoading || getAllRefetching}
                metaState={state.meta}
                updateDisplayId={updateDisplayId}
                removeItem={removeItem}
                filterList={filterList}
                onSortChange={(sort) => {
                    setState({ ...state, sort: sort });
                }}
                refetchDocumentTemplate={(pageNumber: number) => {
                    setState({
                        ...state,
                        meta: {
                            ...state.meta,
                            PageNumber: pageNumber,
                        },
                    });
                }}
                sort={state.sort}
                onEditClick={onEditClick}
            />
        </>
    );
};

export default DocumentArchiveManagement;
